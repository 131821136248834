import Navbar from './NavbarMain';
import { Card } from "react-bootstrap";
import AboutDetails from './AboutDetails';
import AboutSkills from './AboutSkills';

import '../css/about.css';

function Info() {
    return (
        <div>
            <Navbar />
            <div className="container container_info d-flex align-items-center flex-column justify-content-center h-100 container_bg text-white">
                <span>
                    <div className="mainHeader font_bungee text_center contact_header">About</div>
                    <div className="mainHeader font_bungee text_center color_red contact_header">Me</div>
                </span>
                <Card className="container_bg_dark info info_card">
                    <AboutDetails />
                    <AboutSkills />
                </Card>
            </div>
        </div >
    )
}

export default Info;