import React from 'react';
import { Card, Button, Row } from "react-bootstrap";
import { CloudDownloadFill } from 'react-bootstrap-icons';

class AboutDetails extends React.Component {
    render() {
        return (
            <Card className="container_bg about">
                <Card.Header className="font_bungee color_red">Self-introduction</Card.Header>
                <Card.Text className="font_color_aqua font_bolder aboutDetails">
                    Hi, I am a Software engineer living in vienna, Austria.</Card.Text>
                <Card.Text className="color_white aboutDetails">
                    Usually I work on the backend side but also enjoy working on the frontend side.
                    I'd like to think about myself as solution-oriented person who has passion and dedication to his work.
                    I have about 9 years of work experience and enjoy working in a team or as an individual.
                </Card.Text>
                <Row className="align-items-center justify-content-center">
                    <Button variant="primary" className="download" href="/cv"><CloudDownloadFill /> Download CV</Button>
                </Row>
            </Card>
        )
    }
}

export default AboutDetails;