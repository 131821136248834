import { Link } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

function NavbarMain() {
    return (
        <Navbar collapseOnSelect expand="lg" className="navbar_color font_bungee">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" color="blue" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/" className="navbar_font">Home</Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/projects" className="navbar_font"><span ><p className="no_break">My</p><p className="color_yellow no_break">Projects</p></span></Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/info" className="navbar_font"><span ><p className="no_break">My</p><p className="color_green no_break">Info</p></span></Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/about" className="navbar_font"><span ><p className="no_break">About</p><p className="color_red no_break">Me</p></span> </Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/contact" className="navbar_font"><span ><p className="no_break">Contact</p><p className="color_blue no_break">Me</p></span> </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavbarMain;