import React from 'react';
import { Card, ListGroup, ProgressBar } from "react-bootstrap";

class AboutSkillsCard extends React.Component {
    render() {
        return (
            <div>
                <Card.Text className="font_color_aqua font_bolder aboutDetails">
                    {this.props.header} </Card.Text>
                <Card.Text className="color_white aboutDetails">
                    <ListGroup className="container_bg">
                        <ListGroup.Item className="border_none">
                            <ProgressBar className="color_white font_bolder" animated now={this.props.level} label="Experience"/>
                        </ListGroup.Item>

                        {getListItems(this.props.type)}
                    </ListGroup>
                </Card.Text>
            </div>
        )
    }
}

function getListItems(type) {
    return <ListGroup className="container_bg">
        {getEntries(type)}
    </ListGroup>
}


function getEntries(type) {
    const programming = ['Java, C++, Android',
        'Spring, Hibernate, Richfaces, Primefaces, React',
        'Nodejs, EJS, Javascript'
        , 'CSS, Html'];

    const testing = ['Junit',
        'SOAP-UI Testsuites',
        'Selenium'];

    const database = ['Oracle', 'MySql', 'MsSql', 'Sqllite'];

    const others = ['Jenkins',
        'Sonar',
        'Tomcat, Websphere',
        'Oracle, MySql, MsSql, Sqllite',
        'Maven, Gradle'];

    if ("programming" === type) {
        return programming.map((entry) => <ListGroup.Item className="border_none">{entry}</ListGroup.Item>);
    } else if ("testing" === type) {
        return testing.map((entry) => <ListGroup.Item className="border_none">{entry}</ListGroup.Item>);
    } else if ("others" === type) {
        return others.map((entry) => <ListGroup.Item className="border_none">{entry}</ListGroup.Item>);
    } else if ("database" === type) {
        return database.map((entry) => <ListGroup.Item className="border_none">{entry}</ListGroup.Item>);
    }
}

export default AboutSkillsCard;