import NavbarMain from './NavbarMain';
import Projectlink from './Projectlink';
import { Card, Row } from "react-bootstrap";
import '../css/project.css';

import imgRsvp from '../img/rsvp.png';
import imgInvite from '../img/invite.png';
import imgCatchAll from '../img/catchall.png';
import imgCatchThem from '../img/catchthem.png';
import imgPombdev from '../img/pombdev.png';
import imgVaccination from '../img/vaccination.png';

function Projects() {
    return (
        <div>
            <NavbarMain />
            <div className="container container_info d-flex align-items-center flex-column justify-content-center h-100 container_bg text-white">
                <span>
                    <div className="mainHeader font_bungee text_center color_white contact_header">My</div>
                    <div className="mainHeader font_bungee text_center color_yellow contact_header">Projects</div>
                </span>
                <Card className="container_bg_dark info info_card">
                    <Row className="align-items-center justify-content-center">
                        <Projectlink title="RSVP" img={imgRsvp} link="/projects/rsvp"></Projectlink>
                        <Projectlink title="Invite" img={imgInvite} link="/projects/invite"></Projectlink>
                        <Projectlink title="CatchAll" img={imgCatchAll} link="/projects/catchall"></Projectlink>
                        <Projectlink title="CatchThem" img={imgCatchThem} link="/projects/catchthem"></Projectlink>
                        <Projectlink title="Pombdev" img={imgPombdev} link="/projects/pombdev"></Projectlink>
                        <Projectlink title="Vaccination" img={imgVaccination} link="/projects/vaccination"></Projectlink>
                    </Row>
                </Card>

            </div>
        </div >
    )
}

export default Projects;