import './css/main.css';
import Mainpage from './components/Mainpage';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Info from './components/Info';
import About from './components/About';
import Details from './components/Details';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Mainpage />
          </Route>
          <Route path="/projects/:id" >
            <Details />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/info">
            <Info />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>

        </Switch>
      </div>
    </Router>
  );
}

export default App;
