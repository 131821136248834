import React from 'react';
import { Calendar3 } from 'react-bootstrap-icons';
import { Briefcase } from 'react-bootstrap-icons';
import { ChatQuote } from 'react-bootstrap-icons';
import { InfoCircle } from 'react-bootstrap-icons';

class Mainlink extends React.Component {
    render() {
        return (
            <div className="mainlink_hover">
                <div className="card mainlink align-items-center justify-content-center">
                    {renderIcon(this.props)}
                    <span>
                        <p className="mainlink_font">{this.props.header}</p>
                        <p className={getColorClass(this.props)}>{this.props.headerColor}</p>
                    </span>
                </div >
            </div>
        )
    }
}

function renderIcon(props) {
    const icon = props.icon;
    if ("1" === icon) {
        return <Briefcase size={72} className="mainlink_icon color_yellow" />
    }
    if ("2" === icon) {
        return <InfoCircle size={72} className="mainlink_icon color_red" />
    }
    if ("3" === icon) {
        return <ChatQuote size={72} className="mainlink_icon color_blue" />
    }
    if ("4" === icon) {
        return <Calendar3 size={72} className="mainlink_icon color_green" />
    }
    return <Calendar3 size={72} className="mainlink_icon color_white" />
}

function getColorClass(props) {
    const icon = props.icon;
    if ("1" === icon) {
        return "color_yellow mainlink_font";
    }
    if ("2" === icon) {
        return "color_red mainlink_font" ;
    }
    if ("3" === icon) {
        return "color_blue mainlink_font" ;
    }
    if ("4" === icon) {
        return "color_green mainlink_font" ;
    }
    return "color_white mainlink_font" ;
}

export default Mainlink;