import React from 'react';
import { Card, Row, ListGroup, CardGroup } from "react-bootstrap";
import InfoCardContent from './InfoCardContent';

class InfoCard extends React.Component {
    render() {
        return (
            <div className="infoCard">
                <Card.Text>{this.props.header}</Card.Text>
                {getContent(this.props)}
            </div>

        )
    }
}

function getContent(props) {
    const type = props.type;
    if ("Education" === type) {
        return <div>
            <Row className="align-items-center justify-content-center">
                <InfoCardContent header="2008-2012 Vienna, Austria" title="Masters Degree" content="Master in softwarengineering at University of Vienna" />
                <InfoCardContent header="2004-2008 Vienna, Austria" title="Bachelors Degree" content="Bachelor in softwarengineering at University of Vienna" />
            </Row>
        </div>
    }
    else if ("Experience" === type) {
        return [<div>
            <CardGroup>
                <InfoCardContent header="Wipro Technology Austria 2016 - current" title="Technical Lead" content={getContentExperience('wipro')} />
                <InfoCardContent header="Informance Gmbh 2012-2016" title="JAVA Software Engineer" content={getContentExperience('informance')} />
                <InfoCardContent header="PVM Data Services Gmbh 2009 - 2012" title=" Data Analyst ~ 2.5 years" content={getContentExperience('pvm')} />
            </CardGroup>
        </div>]
    }
}

function getContentExperience(edu) {
    if ("wipro" === edu) {
        return <ListGroup className="container_bg">
            <ListGroup.Item>Lead for onshore/offshore software developing team</ListGroup.Item>
            <ListGroup.Item>System specialist</ListGroup.Item>
            <ListGroup.Item>Writing Technical Designs</ListGroup.Item>
            <ListGroup.Item>Code Reviews</ListGroup.Item>
            <ListGroup.Item>Correspondence with customer</ListGroup.Item>
            <ListGroup.Item>CI/CD enhancement</ListGroup.Item>
            <ListGroup.Item>PROD analysis</ListGroup.Item>
            <ListGroup.Item>Software engineering</ListGroup.Item>
        </ListGroup>
    }
    else if ("informance" === edu) {
        return <ListGroup>
            <ListGroup.Item>Frontend development: JSF, Javascript, CSS, Html</ListGroup.Item>
            <ListGroup.Item>Backend development: SOAP dervices, REST dervices, DB</ListGroup.Item>
            <ListGroup.Item>Enhancement of Legacy-Webapplication</ListGroup.Item>
            <ListGroup.Item>Bug fixes in INT/PROD</ListGroup.Item>
        </ListGroup>
    }
    else if ("pvm" === edu) {
        return <ListGroup>
            <ListGroup.Item>Data input and analysis for correctness</ListGroup.Item>
            <ListGroup.Item>Working and enhancemet of excel sheets</ListGroup.Item>
            <ListGroup.Item>Implemenation of contact form in PHP</ListGroup.Item>
        </ListGroup>
    }
}


export default InfoCard;