import { useParams } from "react-router-dom";
import { Card, Button, Row } from "react-bootstrap";
import imgRsvp from '../img/rsvp.png';
import imgInvite from '../img/invite.png';
import imgCatchAll from '../img/catchall.png';
import imgCatchThem from '../img/catchthem.png';
import NavbarMain from './NavbarMain';
import imgPombdev from '../img/pombdev.png';
import imgVaccination from '../img/vaccination.png';

function Details() {
  let { id } = useParams();

  return (
    <div>
      <NavbarMain />
      <div className="project_font container_bg_dark">
        <Row className="align-items-center justify-content-center">
          <Card className="text-center project_card container_bg" style={{ width: '25rem' }}>
            {checkId(id)}
          </Card>
        </Row>
      </div>
    </div>
  );
}

function checkId(id) {
  if (id === "rsvp" || id === "invite" || id === "catchall" || id === "catchthem" || id === "pombdev" || id === "vaccination") {
    return <Card.Body>
      <Card.Title className="font_bungee color_white">{id}</Card.Title>
      <Card.Img className="button-space project_img" variant="top" src={getImage(id)} width="20rem" height="auto" />
      <Card.Text className="font_color_aqua">
        {getDescription(id)}
      </Card.Text>

      <Button className="button-space" variant="primary" href={getLink(id)}>Check out Project</Button>
      <Card.Text>
        Sources can be found on github:
           </Card.Text>
      <Card.Link href={getGit(id)}>{getGit(id)}</Card.Link>
    </Card.Body>
  } else {
    return <Card.Body>
      <Card.Title className="font_bungee color_white">PROJECT NOT FOUND</Card.Title>
    </Card.Body>
  }
}

function getDescription(id) {
  if (id === "rsvp") {
    return "An online wedding rsvp created with nodejs, express and ejs";
  }
  else if (id === "invite") {
    return "An online wedding invitation created with nodejs, express and ejs";
  }
  else if (id === "catchall") {
    return "Small game made with android where you have to catch falling objects";
  }
  else if (id === "catchthem") {
    return "Small game similar to CatchAll game where you have to catch falling objects with a different game mechanic";
  }
  else if (id === "pombdev") {
    return "Simple website done with react and bootstrap to display my information";
  } else if (id === "pombdev") {
    return "Simple website done with react and bootstrap to display my information";
  }
  else if (id === "vaccination") {
    return "Website that shows current state of vaccination in Austria, created with angular and express";
  }
}

function getGit(id) {
  if (id === "rsvp") {
    return "https://github.com/pomb87/rsvp";
  }
  else if (id === "invite") {
    return "https://github.com/pomb87/invite";
  }
  else if (id === "catchall") {
    return "https://github.com/pomb87/CatchAll.git";
  }
  else if (id === "catchthem") {
    return "https://github.com/pomb87/CatchThem";
  }
  else if (id === "pombdev") {
    return "https://github.com/pomb87/pombdev-main";
  }
  else if (id === "vaccination") {
    return "https://github.com/pomb87/vaccination";
  }
}

function getLink(id) {
  if (id === "rsvp") {
    return "https://rsvp.pombdev.com";
  }
  else if (id === "invite") {
    return "https://invite.pombdev.com";
  }
  else if (id === "catchall") {
    return "https://play.google.com/store/apps/details?id=boring.games.pb.catchall&hl=en_US&gl=US";
  }
  else if (id === "catchthem") {
    return "https://github.com/pomb87/CatchThem";
  }
  else if (id === "pombdev") {
    return "https://pombdev.com";
  }
  else if (id === "vaccination") {
    return "https://vaccination.pombdev.com/";
  }
}

function getImage(id) {
  if (id === "rsvp") {
    return imgRsvp;
  }
  else if (id === "invite") {
    return imgInvite;
  }
  else if (id === "catchall") {
    return imgCatchAll;
  }
  else if (id === "catchthem") {
    return imgCatchThem;
  }
  else if (id === "pombdev") {
    return imgPombdev;
  }
  else if (id === "vaccination") {
    return imgVaccination;
  }
}


export default Details;
