import Mainlink from './Mainlink';
import { Link } from "react-router-dom";
import Loader from './Loader';

function Mainpage() {
    return (
        <div className="fullscreen">
            <div className="container d-flex align-items-center flex-column justify-content-center h-100 container_bg text-white">
                <div className="mainHeader font_bungee text_center">Hi, welcome to</div>
                <div className="mainHeader font_bungee text_center font_color_aqua">PombDev</div>
                <div className="row justify-content-center">
                    <Link to="/projects"><Mainlink header="My" headerColor="Projects" icon="1" /></Link>
                    <Link to="/about"><Mainlink header="About" headerColor="Me" icon="2" /></Link>
                    <Link to="/contact"><Mainlink header="Contact" headerColor="Me" icon="3" /></Link>
                    <Link to="/info"><Mainlink header="My" headerColor="Info" icon="4" /></Link>
                </div>
            </div>
        </div >
    )
}

export default Mainpage;