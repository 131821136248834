import React from 'react';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

class Projectlink extends React.Component {
    render() {
        return (
            <div className="project_font container_bg_dark">
                <Card as={Link} to={this.props.link} className="text-center project_card container_bg">
                    <Card.Body>
                        <Card.Title className="font_bungee color_white">{this.props.title}</Card.Title>
                        <Card.Img className="button-space project_img" variant="top" src={this.props.img} width="20rem" height="auto" />
                    </Card.Body>
                </Card>
            </div>
        )
    }
}
export default Projectlink;