import Navbar from './NavbarMain';
import { Card, Row } from "react-bootstrap";
import imgHead from '../img/headComic.jpg';

function Contact() {
    return (
        <div>
            <Navbar />
            <Row className="h-100 align-items-center justify-content-center font_nunito">
                <Card style={{ width: '25rem' }}>
                    <Card.Header className="text_center font_big font_bold container_bg contact_header_padding">
                        <Card.Text className="font_bungee color_white contact_header">Contact</Card.Text>
                        <Card.Text className="font_bungee color_blue contact_header">Me</Card.Text>
                    </Card.Header>
                    <Card.Body className="text_center">
                        <Card.Img className="img_round" src={imgHead} />
                        <Card.Title className="font_bolder color_blue font_bungee">Pombdev</Card.Title>
                        <Card.Text className="font_color_grey font_nunito">Software engineer</Card.Text>
                        <Card.Text className="font_color_grey font_nunito">Location: Austria</Card.Text>
                        <Card.Footer>
                            <Card.Text>
                                Hi, if you want to get in contact with me, please use below email address
                        </Card.Text>
                            <a href="mailto:pombdev@gmail.com">pombdev@gmail.com</a>
                        </Card.Footer>
                    </Card.Body>
                </Card>
            </Row>
        </div >

    )
}

export default Contact;
