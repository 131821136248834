import React from 'react';
import { Card } from "react-bootstrap";

class InfoCardContent extends React.Component {
    render() {
        return (
            <Card className="container_bg InfoCardContent_main">
                <Card.Header className="font_bungee color_green">{this.props.header}</Card.Header>
                <Card.Text className="font_color_aqua font_bolder infoCardContent">{this.props.title}</Card.Text>
                <Card.Text className="color_white infoCardContent">{this.props.content}</Card.Text>
            </Card>
        )
    }
}

export default InfoCardContent;