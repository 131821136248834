import React from 'react';
import { Card, CardGroup, CardDeck } from "react-bootstrap";
import AboutSkillsCard from "./AboutSkillsCard";

class AboutSkills extends React.Component {
    render() {
        return (
            <Card className="container_bg about">
                <Card.Header className="font_bungee color_red">Skills</Card.Header>
                <CardGroup>
                    <Card className="container_bg aboutSkillsCard">
                        <AboutSkillsCard header="Programming" type="programming" level="95" />
                    </Card>
                    <Card className="container_bg aboutSkillsCard">
                        <AboutSkillsCard header="Testing" type="testing" level="90" />
                    </Card>
                </CardGroup>
                <CardGroup>
                    <Card className="container_bg aboutSkillsCard">
                        <AboutSkillsCard header="Database" type="database" level="85" />
                    </Card>
                    <Card className="container_bg aboutSkillsCard">
                        <AboutSkillsCard header="Others" type="others" level="70" />
                    </Card>
                </CardGroup>
            </Card>
        )
    }
}

export default AboutSkills;