import React from 'react';
import { Card } from "react-bootstrap";
import InfoCard from './InfoCard';

class InfoMain extends React.Component {
    render() {
        return (
            <div>
                <Card.Header className="infoMain">
                    <Card.Text className="font_bungee font_normal">{this.props.header}</Card.Text>
                </Card.Header>
                <InfoCard type={this.props.header} />                
            </div>
        )
    }
}

export default InfoMain;